var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("feather-icon", {
        attrs: {
          title: "View Details",
          icon: "EyeIcon",
          svgClasses: "h-5 w-5 mr-4 hover:text-primary cursor-pointer",
        },
        on: { click: _vm.onView },
      }),
      _c("feather-icon", {
        attrs: {
          title: "Edit",
          icon: "Edit3Icon",
          svgClasses: "h-5 w-5 mr-4 hover:text-primary cursor-pointer",
        },
        on: { click: _vm.onEditRule },
      }),
      _c("feather-icon", {
        attrs: {
          title: "Delete",
          icon: "TrashIcon",
          svgClasses: "h-5 w-5 hover:text-danger cursor-pointer",
        },
        on: {
          click: function ($event) {
            _vm.showDeletePopup = true
          },
        },
      }),
      _c(
        "vs-prompt",
        {
          attrs: {
            title: "Delete Rule",
            "accept-text": "Delete",
            active: _vm.showDeletePopup,
          },
          on: {
            accept: _vm.deleteRule,
            "update:active": function ($event) {
              _vm.showDeletePopup = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row mt-2 mb-2" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c("div", {}, [
                _vm._v(
                  "Are you sure you want to delete the rule - " +
                    _vm._s(_vm.params.data.ruleName) +
                    "?"
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }